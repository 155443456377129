import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home/index.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/welcome",
		name: "Welcome",
		component: () => import("../views/Welcome/index.vue"),
	},
	{
		path: "/benefits",
		name: "Benefits",
		component: () => import("../views/Benefits/index.vue"),
	},
	{
		path: "/missions",
		name: "Missions",
		component: () => import("../views/Missions/index.vue"),
	},
	{
		path: "/detail",
		name: "Detail",
		component: () => import("../views/Detail/index.vue"),
	},
	{
		path: "/intro",
		name: "Intro",
		component: () => import("../views/Intro/index.vue"),
	},
	{
		path: "/ncoin-instruction",
		name: "NcoinInstruction",
		component: () => import("../views/NcoinInstruction/index.vue"),
	},
	{
		path: "/binding",
		name: "Binding",
		component: () => import("../views/Binding/index.vue"),
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/",
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		return { top: 0 };
	},
});

export default router;
